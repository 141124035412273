// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL_DELIMITER: 'https://kopshop-file-upload.s3.amazonaws.com',
  AES_KEY: 'OAc1Pa37gUznk5zXBzZC1VyHf2aC+uQUdx++uSHYbtdcva0cG9vM+86Xv0m/Ox39J3SQ3Ck+xBZDjihlA+Va9g==',
  RSA_PRIVATE_KEY: `-----BEGIN RSA PRIVATE KEY-----
  MIIBOQIBAAJBAIkDttt/+F3yo+0SCIQrmsVoMm/g7Y2lw60FrKxlGYaumxlD+Sgl
  Zq6yB85tl/KiWz7HuLDKTLnkt7FeRRMeqeECAwEAAQJAb1mKaRa2NTu1RzxpmWiK
  jrYgj1XgGUbHIL1E2JINTas0r6SDX72vpHvS09tidL5dKt/d6TopycOdovGFbr7p
  cQIhANK2gLW5TsutI/AfB3q4eq4+fA92ps6/fisSXJPfnog9AiEApnZM37wkKWEQ
  Kj/c0vYGAHIr4tyTGDcBCKBqBoZ0XnUCIH5S/5Lyx3SAFn2MfLVsB9CSkVnL7nEg
  iIlV9r6IX8iZAiATtc5Eb7KYc7XGSumK0TbEtsAbjKKeK2Uj8Fqdiy4cQQIgJg7W
  gWM7xL960/tHOZEJeAM1GhHi20EKGa++MC9qMMI=
  -----END RSA PRIVATE KEY-----`,
  apiUrl: 'https://api-staging.forensickopshop.ie/v1' // Staging  API url
  // apiUrl: 'https://api.forensickopshop.ie/v1',  // Production API url
  // apiUrl: 'http://192.170.2.54:3000/v1',
  // apiUrl: 'http://52.16.29.179:3000/v1'
  // apiUrl: 'http://localhost:3000/v1'
  // apiUrl: 'https://f85e-112-196-113-2.ngrok.io/v1'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
